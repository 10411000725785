import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TopicRoute = ({ data }) => {
	const { t } = useTranslation();
	const topicMeta = data.topicMain.frontmatter; // get topic metadata

	let mainArticles = data.mainArticles.edges; // get all "main topic" articles
	let relatedArticles = data.relatedArticles.edges; // get all "related topic" articles

	const allArticles = mainArticles.concat(relatedArticles); // combine the two above in a single article list

	let featuredArticles;

	if (topicMeta.featured) {
		// if we have featured articles

		// make an array of featured articles: if the featured articles don't exist in "allArticles," remove them
		featuredArticles = topicMeta.featured
			.map((title) =>
				allArticles.find((article) => article.node.frontmatter.title === title)
			)
			.filter((e) => e);

		// remove featured articles from main article list
		mainArticles = mainArticles.filter(
			(article) => !featuredArticles.includes(article)
		);

		// remove featured articles from related article list
		relatedArticles = relatedArticles.filter(
			(article) => !featuredArticles.includes(article)
		);
	}

	let featuredArticleRoll;

	if (topicMeta.featured) {
		featuredArticleRoll = featuredArticles.map((article) => (
			<Link
				to={article.node.fields.slug}
				className="group p-4 block relative col-span-2 md:col-span-1 mb-4"
			>
				<GatsbyImage
					image={getImage(article.node.frontmatter.featuredimage)}
					className="rounded transition opacity-90 group-hover:opacity-100 aspect-video"
				/>

				<h3 className="pt-4 text-l md:text-xl my-2 text-white  leading-none">
					{article.node.frontmatter.title}
				</h3>
				<p className="text-xs md:text-sm text-white py-4  border-t-2 border-white">
					{article.node.frontmatter.description}
				</p>
			</Link>
		));
	}

	const mainArticleRoll = mainArticles.map((article) => (
		<Link
			to={article.node.fields.slug}
			className="group p-4 block col-span-2 md:col-span-1 mb-4"
		>
			<GatsbyImage
				image={getImage(article.node.frontmatter.featuredimage)}
				className="rounded transition brightness-90 group-hover:brightness-100 aspect-video"
			/>
			<h3 className="text-2xl my-2 text leading-none">
				{article.node.frontmatter.title}
			</h3>
			<p className="text-basis text-stone-600">{article.node.frontmatter.description}</p>
		</Link>
	));

	const relatedArticleRoll = relatedArticles.map((article) => (
		<Link
			to={article.node.fields.slug}
			className="group p-4 block col-span-1 md:col-span-1 mb-4"
		>
			<GatsbyImage
				image={getImage(article.node.frontmatter.featuredimage)}
				className="rounded transition brightness-90 group-hover:brightness-100 aspect-video"
			/>
			<h3 className="text-xl my-2 text leading-none">
				{article.node.frontmatter.title}
			</h3>
			<p className="text-basis text-stone-600">{article.node.frontmatter.description}</p>
		</Link>
	));

	return (
		<Layout>
			<section className="section">
				<Seo
					title={"Topic: " + topicMeta.title}
					description={topicMeta.topicdescription}
					image={getSrc(topicMeta.featuredimage)}
					article={false}
				/>
				<div className="heroImageGroup relative group">
					<div className="aspect-[3/2] md:aspect-[8/3] brightness-75 transition group-hover:saturate-50  bg-no-repeat bg-center bg-fixed bg-cover">
						<GatsbyImage
							image={getImage(topicMeta.featuredimage)}
							className="h-full"
						/>
					</div>

					<div className="absolute bottom-5 md:bottom-[20%] lg:bottom-5 xl:bottom-[20%]  text-white w-full">
						<div className="gecko-wrapper">
							<h1 className="lg:text-6xl text-xl uppercase bg-black leading-tight md:text-4xl w-full md:w-2/3 font-sans lg:py-4 pl-4 transition">
								{t("common:topic.topic")}: {topicMeta.title}
							</h1>
						</div>
					</div>
				</div>

				<div className="section about-gecko-section bg-stone-200 w-full">
					<div className="prose text-sm md:text-xl gecko-wrapper py-2 lg:py-10">
						<ReactMarkdown>{topicMeta.topicdescription}</ReactMarkdown>
					</div>
				</div>

				{featuredArticles && (
					<div className="section featured-articles-section bg-gecko-red w-full py-8">
						<h1 className="uppercase text-center text-2xl py-8 text-white">
							{t("common:topic.spotlight")}
						</h1>
						<div className="gecko-wrapper grid grid-cols-1 md:grid-cols-2 gap-4">
							{featuredArticleRoll}
						</div>
					</div>
				)}

				{mainArticles.length > 0 && (
					<div className="mainArticleRoll-wrapper gecko-wrapper">
						<h1 className="uppercase text-center text-2xl py-14 text-stone-400">
							{t("common:topic.main")}
						</h1>
						<div className="mainArticleRoll grid grid-cols-1 md:grid-cols-3 gap-4">
							{mainArticleRoll}
						</div>
					</div>
				)}

				{relatedArticles.length > 0 && (
					<div className="bg-stone-200">
						<div className="relatedArticleRoll-wrapper gecko-wrapper">
							<h1 className="uppercase text-center text-2xl text-stone-400 py-14">
								{t("common:topic.related")}
							</h1>
							<div className="relatedArticleRoll grid grid-cols-2 md:grid-cols-4 gap-4">
								{relatedArticleRoll}
							</div>
						</div>
					</div>
				)}
			</section>
		</Layout>
	);
};

export default TopicRoute;

export const topicPageQuery = graphql`
	query TopicPage($topic: String!, $slug: String!, $language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		topicMain: markdownRemark(
			frontmatter: { title: { eq: $topic }, related_topics: {} }
			fields: { language: { eq: $language } }
		) {
			frontmatter {
				title
				topicdescription
				featured
				featuredimage {
					childImageSharp {
						gatsbyImageData(
							width: 2000
							quality: 50
							webpOptions: { quality: 70 }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
		}
		mainArticles: allMarkdownRemark(
			filter: {
				frontmatter: { main_topic: { eq: $slug } }
				fields: { language: { eq: $language }, langAvailable: { eq: true } }
			}
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			totalCount
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						featuredimage {
							childImageSharp {
								gatsbyImageData(
									width: 800
									quality: 50
									webpOptions: { quality: 70 }
								)
							}
						}
						description
					}
				}
			}
		}
		relatedArticles: allMarkdownRemark(
			filter: {
				frontmatter: { related_topics: { eq: $slug } }
				fields: { language: { eq: $language }, langAvailable: { eq: true } }
			}
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			totalCount
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						featuredimage {
							childImageSharp {
								gatsbyImageData(
									width: 800
									quality: 50
									webpOptions: { quality: 70 }
								)
							}
						}
						description
					}
				}
			}
		}
	}
`;

/////////////////////////
// TEST BOILERPLATE CODE
/////////////////////////

// import React from "react";
// const AboutPage = () => (
// 	<main>
// 		<h1>You should see a topic page here, but it's offline for some testing.</h1>
// 	</main>
// );
// export default AboutPage;
